import React, { Component } from 'react';
import Mainsidebar from './mainsidebar';
import { Grid, Sidebar, Item,Icon, Header, Image, Tab, Divider } from 'semantic-ui-react';
import {Link} from 'react-router-dom'

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {}        
    }

    render() {
        return (
            <div style={{height: 50}}>
            <Grid style={{ height: 'auto' }}>
                <Grid.Column>
                    <Mainsidebar />
                </Grid.Column>
                <Grid.Column width="12" style={{paddingLeft: "100px"}}>
                    <Header as='h2' icon textAlign='center' style={{marginTop: "50px"}}>
                    <Icon name='tree' circular />
                    <Header.Content>David's Christmas List</Header.Content>
                    </Header>
                    <Divider/>
                    {/*Long Sleeve Shirts*/}
                    <Item.Group >
                        <Item>
                            <Item.Image size='tiny' src='https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRTX2yuq0p0vRhLw0GGRuWH967j2ll9sV-SyA&usqp=CAU' />
                            <Item.Content verticalAlign="middle">
                                <Item.Header>Long Sleeve Shirts</Item.Header>
                                <Item.Description>
                                    I love my oculus shirt and my NASA shirt. Would be nice to have some more shirts of this style.
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>

                    {/*Oculus Quest Case*/}
                    <Item.Group style={{marginTop: "50px"}}>
                        <Item>
                            <Item.Image size='tiny' src='https://images-na.ssl-images-amazon.com/images/I/71cEbzoglsL._AC_SL1500_.jpg' />
                            <Item.Content verticalAlign="middle">
                                <Item.Header as="a" target="_blank" href="https://www.amazon.ca/Oculus-Carrying-Lightweight-Portable-Protection/dp/B08F5VCNCY/ref=sr_1_5?dchild=1&keywords=oculus+quest+2+case&qid=1604465630&sr=8-5">Oculus Quest 2 Case </Item.Header>
                                <Item.Description>
                                    A noice case for ma new fancy face tv
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>

                    {/*Memory express giftcart*/}
                    <Item.Group style={{marginTop: "50px"}}>
                        <Item>
                            <Item.Image size='tiny' src='https://s3-media0.fl.yelpcdn.com/bphoto/4DfzXq9OrQjbv3p7n1ul9A/o.jpg' />
                            <Item.Content verticalAlign="middle">
                                <Item.Header as="a" target="_blank" href="https://www.memoryexpress.com/Search/Products?Search=gift+card">Memory Express Gift Card</Item.Header>
                                <Item.Description>
                                    Feed my addiction to having a fast computer :)
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>

                    {/*Escape From Tarkov*/}
                    <Item.Group style={{marginTop: "50px"}}>
                        <Item>
                            <Item.Image size='tiny' src='https://tarkovmerchstore.com/static/products/dcb278ae-829f-417d-9730-23e97121b9c0.jpg' />
                            <Item.Content verticalAlign="middle">
                                <Item.Header as="a" target="_blank" href="https://tarkovmerchstore.com/Clothes">Escape From Tarkov Clothes </Item.Header>
                                <Item.Description>
                                    This is the clothing store to the game that I streamed all summer
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                    <Item.Group style={{marginTop: "50px"}}>
                        <Item>
                            <Item.Image size='tiny' src='https://tarkovmerchstore.com/static/products/d4993656-9d29-4932-a1cc-f47c85d6f691.jpg' />
                            <Item.Content verticalAlign="middle">
                                <Item.Header as="a" target="_blank" href="https://tarkovmerchstore.com/products/product/184">Telnik EFT Shirt </Item.Header>
                                <Item.Description>
                                    This shirt is in a diff place then the rest of the merch so thought I'd put this here
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                    
                    {/*AIO CPU cooler*/}
                    <Item.Group style={{marginTop: "50px"}}>
                        <Item>
                            <Item.Image size='tiny' src='https://cdn.shopify.com/s/files/1/1991/2331/products/VRPower_8_5000x.jpg?v=1601365374' />
                            <Item.Content verticalAlign="middle">
                                <Item.Header as="a" target="_blank" href="https://rebuffreality.com/products/vr-power?variant=32906811244626">VR Power for Oculus Quest 2 with Elite Strap</Item.Header>
                                <Item.Description>
                                    This is a good battery to snap onto the strap of my oculus quest 2
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>

                    {/*SSD*/}
                    <Item.Group style={{marginTop: "50px"}}>
                        <Item>
                            <Item.Image size='tiny' src='https://media.memoryexpress.com/Images/Products/Large/MX70319-0.jpg' />
                            <Item.Content verticalAlign="middle">
                                <Item.Header as="a" target="_blank" href="https://www.memoryexpress.com/Products/MX70319">1TB SSD</Item.Header>
                                <Item.Description>
                                    I don't know much about brand, only ones I know anything about are the samsung and kingston
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>

                    {/*AIO cooler*/}
                    <Item.Group style={{marginTop: "50px"}}>
                        <Item>
                            <Item.Image size='tiny' src='https://media.memoryexpress.com/Images/Products/Large/MX76142-0.jpg' />
                            <Item.Content verticalAlign="middle">
                                <Item.Header as="a" target="_blank" href="https://www.memoryexpress.com/Products/MX76142">Thermaltake AIO cooler</Item.Header>
                                <Item.Description>
                                    Makes computer brain not get too hot
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
            </Grid>
            </div>
        );
    }
}

export default Projects;