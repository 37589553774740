import React, { Component } from 'react';
import { Sidebar, Menu, Icon } from 'semantic-ui-react'
import { Link } from "react-router-dom"

class mainsidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Sidebar
                as={Menu}
                animation='overlay'
                icon='labeled'
                inverted
                vertical
                visible
            >
                <Menu.Item as={Link} to='/' position="left">
                    <Icon name='home' />
                </Menu.Item>
                <Menu.Item as={Link} to='/projects'>
                    <Icon name='tree' />
                </Menu.Item>
            </Sidebar>);
    }
}

export default mainsidebar;