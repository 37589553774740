import React, { Component } from 'react';
import {  Menu, Icon, Grid, Header, Divider, Sidebar, Segment } from 'semantic-ui-react';
import Mainsidebar from './mainsidebar';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                <Grid style={{ height: 'auto' }}>
                <Grid.Column>
                    <Mainsidebar/>
                </Grid.Column>
                <Grid.Column width="12" style={{paddingLeft: "100px"}}>
                    <Header as='h2' icon textAlign='center' style={{marginTop: "50px"}}>
                        <Icon name='home' circular />
                        <Header.Content>About me</Header.Content>
                    </Header>
                    
                        Hi. My name is David, I suck at making christmas lists.
                </Grid.Column>
                </Grid>
            </div>
            
         );
    }
}
 
export default Home;