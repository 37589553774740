import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './home';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Projects from './projects';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/projects' component={Projects}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
